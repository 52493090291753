<template>
  <SetupForm
    v-bind="$attrs"
    :title="metadata.title"
    :next-text="metadata.nextText"
    @submit="updateEntries"
    v-on="$listeners"
  >
    <template #extension>
      <p v-for="(text, idx) in metadata.extension" :key="idx">
        {{ text }}
      </p>
    </template>
    <VxFormGroup :title="metadata.groupTitle">
      <JournalEntryTable
        :items="entries"
        :type="journalType"
        :accounts="accounts"
        @change="onEntryChange"
      />
    </VxFormGroup>
    <VxAlert type="info" class="mt-5">
      <p>
        {{ alertMetadata.syncText }}
        <RaiLink :disabled="syncing" @click="syncAccounts">
          {{ alertMetadata.syncLinkText }}
        </RaiLink>
        <v-progress-circular
          v-if="syncing"
          :size="20"
          indeterminate
        ></v-progress-circular>
      </p>
      <p class="mb-0">
        {{ alertMetadata.noAccountsText }}
        <RaiLink href="alertMetadata.noAccountsLink.href">
          {{ alertMetadata.noAccountsLink.text }}
        </RaiLink>
      </p>
    </VxAlert>
  </SetupForm>
</template>

<script>
import SetupForm from "../SetupForm.vue";
import JournalEntryTable from "./JournalEntryTable.vue";
import { VxAlert, RaiLink, VxFormGroup } from "@/core-ui";
import { JournalType } from "./constants";

export default {
  name: "JournalEntrySetup",
  components: { SetupForm, VxAlert, RaiLink, JournalEntryTable, VxFormGroup },
  inheritAttrs: false,
  props: {
    journalType: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(JournalType).indexOf(value) !== -1;
      },
    },
    entries: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    syncing: false,
  }),
  computed: {
    metadata() {
      return this.$t(
        `settings.automation.quickbooks.journalEntrySetup.${this.journalType}`
      );
    },
    alertMetadata() {
      return this.$t("settings.automation.quickbooks.journalEntryAlert");
    },
  },
  methods: {
    syncAccounts() {
      this.syncing = true;
      this.$emit("sync:accounts", {
        type: this.journalType,
        resolve: () => {
          this.syncing = false;
        },
      });
    },
    updateEntries(form) {
      const values = this.entries.map((entry) => ({
        qbAccountId: form.values[entry.qbField],
        qbField: entry.qbField,
      }));

      this.$emit("update:entries", {
        ...form,
        values,
        type: this.journalType,
      });
    },
    onEntryChange(entry) {
      const { qbAccountId, qbField } = entry;
      const entryToUpdate = this.entries.find((x) => x.qbField === qbField);
      entryToUpdate.qbAccountId = qbAccountId;
    },
  },
};
</script>
