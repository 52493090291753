export const JournalType = {
  BUYS: "buys",
  COGS: "cogs",
  SALES: "sales",
};

export const JournalEntryStatus = {
  SENT: "sent",
  NOT_SENT: "not-sent",
  ERROR: "error",
};

export const JournalEntryType = {
  CREDIT: "credit",
  DEBIT: "debit",
  DUAL: "dual",
};

export const QbAccountLink = {
  INVENTORY_USED: "inv-used",
  INVENTORY_NEW: "inv-new",
  INVETORY_OTHER: "inv-other",
  STORE_CASH: "store-cash",
  STORE_CREDIT: "store-credit",
  GIFT_CREDIT: "gift-credit",
  LAYWAY: "layway",
  COGS_NEW: "cogs-new",
  COGS_USED: "cogs-used",
  COGS_OTHER: "cogs-other",
};
