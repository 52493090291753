<template>
  <tr class="journal-entry-table__row">
    <td>
      <VxSelect
        v-model="innerEntry.qbAccountId"
        :placeholder="metadata.accountSelect.placeholder"
        :name="innerEntry.qbField"
        :items="accounts"
        rules="required"
        hide-details
        dense
        @change="updateEntry"
      />
    </td>
    <td class="text--secondary">{{ description }}</td>
    <MoneyCell :value="innerEntry.debit" />
    <MoneyCell :value="innerEntry.credit" />
  </tr>
</template>

<script>
import MoneyCell from "../MoneyCell.vue";
import { VxSelect } from "@/core-ui";

export default {
  name: "JournalEntryTableRow",
  components: { MoneyCell, VxSelect },
  inject: {
    table: {
      default: {
        accounts: [],
        fields: {},
      },
    },
  },
  props: {
    entry: {
      type: Object,
      default: () => ({
        qbAccountId: 0,
        qbField: "",
        debit: null,
        credit: null,
        meta: {
          allowedTypes: null,
          allowedSubtypes: null,
          description: "",
          linked: null,
          type: "",
        },
      }),
    },
  },
  data: (vm) => ({
    innerEntry: { ...vm.entry },
  }),
  computed: {
    metadata() {
      return this.$t("settings.automation.quickbooks.journalEntryTableRow");
    },
    description() {
      return this.table.fields[this.entry.qbField];
    },
    accounts() {
      return this.table.accounts.map((account) => {
        let disabled = true;
        // If allowed types specified, check if account type is included
        if (
          this.entry.meta?.allowedTypes &&
          this.entry.meta.allowedTypes.indexOf(account.type) >= 0
        ) {
          // If allowed subtypes specified, check also if account subtype is included
          if (this.entry.meta?.allowedSubtypes) {
            if (this.entry.meta.allowedSubtypes.indexOf(account.subtype) >= 0) {
              disabled = false;
            }
          } else {
            disabled = false;
          }
        }

        return {
          ...account,
          disabled,
        };
      });
    },
  },
  watch: {
    entry: {
      deep: true,
      handler(value) {
        this.innerEntry = { ...value };
      },
    },
  },
  methods: {
    updateEntry() {
      const { meta } = this.innerEntry;
      this.$emit("update:entry", {
        ...this.innerEntry,
        meta: { ...meta, description: this.description },
      });
    },
  },
};
</script>
