<template>
  <VxSimpleTable
    class="journal-entry-table"
    :hoverable="false"
    :headers="headers"
    :items="items"
  >
    <template #item="{ item }">
      <JournalEntryTableRow :entry="item" @update:entry="change" />
    </template>
    <template #body.append>
      <tr>
        <td></td>
        <td class="text-overline">{{ metadata.totalText }}</td>
        <MoneyCell :value="totalDebit" />
        <MoneyCell :value="totalCredit" />
      </tr>
    </template>
  </VxSimpleTable>
</template>

<script>
import sumBy from "lodash/sumBy";
import MoneyCell from "../MoneyCell.vue";
import VxSimpleTable from "../VxSimpleTable.vue";
import JournalEntryTableRow from "./JournalEntryTableRow.vue";
import { JournalType } from "./constants";

export default {
  name: "JournalEntryTable",
  components: { VxSimpleTable, JournalEntryTableRow, MoneyCell },
  provide() {
    return { table: this };
  },
  inject: {
    view: {
      default: null,
    },
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(JournalType).indexOf(value) !== -1;
      },
    },
    items: {
      type: Array,
      default: () => [],
    },
    accounts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalDebit() {
      return sumBy(this.items, (x) => x.debit);
    },
    totalCredit() {
      return sumBy(this.items, (x) => x.credit);
    },
    headers() {
      const { qbAccountText, descriptionText, debitText, creditText } =
        this.metadata.headers;

      return [
        { text: qbAccountText, value: "qbAccountId", width: "250px" },
        { text: descriptionText, value: "description" },
        { text: debitText, value: "debit" },
        { text: creditText, value: "credit" },
      ];
    },
    fields() {
      return this.$t("settings.automation.quickbooks.fields")[this.type];
    },
    metadata() {
      return this.$t("settings.automation.quickbooks.journalEntryTable");
    },
  },
  methods: {
    change(entry) {
      if (!entry.meta.linked) {
        this.$emit("change", entry);
        return;
      }

      this.changeLinked(entry);
    },
    async changeLinked(entry) {
      const hasViewDefined = this.view !== null;
      const linkedEntries = hasViewDefined
        ? this.getLinkedCrossJournalEntriesFor(entry)
        : this.getLinkedJournalEntriesFor(entry);

      const linkedEntriesHaveAccount = linkedEntries.every(
        (x) => x.qbAccountId > 0
      );

      if (linkedEntriesHaveAccount) {
        const override = await this.showOverrideConfirmDialog(entry);

        if (!override) {
          this.$emit("change", entry);
          return;
        }
      }

      if (hasViewDefined) {
        linkedEntries.forEach((x) => (x.qbAccountId = entry.qbAccountId));
        return;
      }

      this.$emit("change:linked", {
        qbAccountId: entry.qbAccountId,
        linked: linkedEntries,
      });
    },
    getLinkedJournalEntriesFor(entry, items = null) {
      const entries = Array.isArray(items) ? items : this.items;
      return entries.filter((x) => x.meta.linked === entry.meta.linked);
    },
    getLinkedCrossJournalEntriesFor(entry) {
      if (!this.view?.automation) {
        return [];
      }

      const allowedJournals = Object.values(JournalType);
      const entries = allowedJournals.flatMap((x) => this.view.automation[x]);
      return this.getLinkedJournalEntriesFor(entry, entries);
    },
    showOverrideConfirmDialog(entry) {
      const { title, okText, cancelText } = this.metadata.overrideConfirmDialog;
      const message = this.$t(
        "settings.automation.quickbooks.journalEntryTable.overrideConfirmDialog.message",
        { entry: entry.meta.description }
      );

      return this.$dialog.confirm({
        title,
        message,
        okText,
        cancelText,
      });
    },
  },
};
</script>
