<template>
  <div class="journal-calendar">
    <v-progress-linear v-show="loading" indeterminate />
    <v-toolbar class="pl-1" flat>
      <VxBtn icon color="grey" @click="previous">
        <v-icon> $left </v-icon>
      </VxBtn>
      <v-toolbar-title v-if="calendarMounted" class="px-1">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <VxBtn icon color="grey" :disabled="nextDisabled" @click="next">
        <v-icon> $right </v-icon>
      </VxBtn>
      <v-spacer></v-spacer>
      <VxBtn outlined color="grey" @click="today"> Today </VxBtn>
    </v-toolbar>
    <v-sheet :height="height">
      <v-calendar
        ref="calendar"
        v-model="current"
        type="month"
        @click:date="clickEntry"
        @click:day="clickEntry"
        @change="change"
      >
        <template #day-label="{ day }">
          <span class="text-caption">{{ day }}</span>
        </template>
        <template #day="{ date, outside }">
          <JournalEntryCalendarDay
            v-if="!outside"
            :entry="entriesObject[date]"
          />
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import { VxBtn } from "@/core-ui";
import JournalEntryCalendarDay from "./JournalEntryCalendarDay.vue";

const TODAY = new Date();

export default {
  name: "JournalEntryCalendar",
  components: { VxBtn, JournalEntryCalendarDay },
  props: {
    entries: {
      type: Array,
      default: () => [],
    },
    height: {
      type: [String, Number],
      default: 400,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    current: TODAY.toDateString(),
    calendarMounted: false,
  }),
  computed: {
    nextDisabled() {
      return this.currentMonth === this.todayMonth;
    },
    currentMonth() {
      return new Date(this.current).getMonth();
    },
    todayMonth() {
      return TODAY.getMonth();
    },
    entriesObject() {
      return Object.fromEntries(this.entries?.map((x) => [x.createdAt, x]));
    },
  },
  mounted() {
    this.calendarMounted = true;
  },
  methods: {
    clickEntry(event) {
      const entry = this.entriesObject[event.date];
      const outside = this.isOutside(event);

      if (!entry || outside) {
        return;
      }

      this.$emit("click:entry", entry);
    },
    isOutside(event) {
      const selector = ".v-calendar-weekly__day.v-outside";
      return !!event.nativeEvent.target.closest(selector);
    },
    change(event) {
      this.$emit("change", {
        startDate: event.start.date,
        endDate: event.end.date,
      });
    },
    today() {
      this.current = "";
      this.$emit("today");
    },
    previous() {
      this.$refs.calendar.prev();
      this.$emit("previous");
    },
    next() {
      this.$refs.calendar.next();
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss">
.journal-calendar .v-calendar .v-calendar-weekly__day {
  &:not(.v-future):not(.v-outside):hover {
    cursor: pointer;
  }

  &.v-future,
  &.v-outside {
    & > .v-calendar-weekly__day-label:hover {
      cursor: default !important;
    }
  }

  &.v-outside > .v-calendar-weekly__day-label {
    color: transparent !important;
  }
}
</style>
