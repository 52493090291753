var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AccountSetup',_vm._g(_vm._b({attrs:{"accounts":_vm.accounts},on:{"change:account":_vm.onAccountChange,"submit":_vm.updateAutomation},scopedSlots:_vm._u([{key:"account",fn:function({ attrs, on }){return [_c('AccountSelect',_vm._g(_vm._b({attrs:{"loadable":"","name":"integrationQuickbooksId"},model:{value:(_vm.form.integrationQuickbooksId),callback:function ($$v) {_vm.$set(_vm.form, "integrationQuickbooksId", $$v)},expression:"form.integrationQuickbooksId"}},'AccountSelect',attrs,false),on))]}}])},'AccountSetup',_vm.$attrs,false),_vm.$listeners),[(_vm.form.integrationQuickbooksId > 0)?_c('VxFormGroup',{attrs:{"title":_vm.$t('settings.automation.quickbooks.connectAccount.classes.groupTitle')}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.quickbooks.connectAccount.classes.description" ))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('VxSelect',{attrs:{"loading":_vm.syncingClasses,"disabled":!_vm.canSelectClass,"no-data-text":_vm.$t(
              'settings.automation.quickbooks.connectAccount.classes.select.noDataText'
            ),"name":"integrationQuickbooksClassId","label":_vm.$t(
              'settings.automation.quickbooks.connectAccount.classes.select.label'
            ),"placeholder":_vm.$t(
              'settings.automation.quickbooks.connectAccount.classes.select.placeholder'
            ),"items":_vm.allClassesToSelect},model:{value:(_vm.form.integrationQuickbooksClassId),callback:function ($$v) {_vm.$set(_vm.form, "integrationQuickbooksClassId", $$v)},expression:"form.integrationQuickbooksClassId"}})],1)],1),_c('p',{staticClass:"text--secondary text-caption"},[_vm._v(" "+_vm._s(_vm.$t("settings.automation.quickbooks.connectAccount.classes.syncText"))+" "),_c('RaiLink',{staticClass:"text-caption",attrs:{"disabled":_vm.syncingClasses},on:{"click":_vm.syncClasses}},[_vm._v(" "+_vm._s(_vm.$t( "settings.automation.quickbooks.connectAccount.classes.syncBtnText" ))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }