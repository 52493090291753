<template>
  <VxSimpleTable
    class="journal-entry-review-table"
    :hoverable="false"
    :headers="headers"
    :items="entries"
  >
    <template #item="{ item }">
      <tr>
        <td>{{ item.description }}</td>
        <td>{{ item.klass }}</td>
        <MoneyCell :value="item.debit" />
        <MoneyCell :value="item.credit" />
      </tr>
    </template>
    <template #body.append>
      <tr>
        <td colspan="2" class="text-overline">{{ metadata.totalText }}</td>
        <MoneyCell :value="totalDebit" />
        <MoneyCell :value="totalCredit" />
      </tr>
    </template>
  </VxSimpleTable>
</template>

<script>
import sumBy from "lodash/sumBy";
import MoneyCell from "../MoneyCell.vue";
import VxSimpleTable from "../VxSimpleTable.vue";
import { JournalType } from "./constants";

export default {
  name: "JournalEntryReviewTable",
  components: { VxSimpleTable, MoneyCell },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(JournalType).indexOf(value) !== -1;
      },
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalDebit() {
      return sumBy(this.items, (x) => x.debit);
    },
    totalCredit() {
      return sumBy(this.items, (x) => x.credit);
    },
    headers() {
      const { qbAccountText, classText, debitText, creditText } =
        this.metadata.headers;

      return [
        { text: qbAccountText, value: "description" },
        { text: classText, value: "class" },
        { text: debitText, value: "debit" },
        { text: creditText, value: "credit" },
      ];
    },
    entries() {
      return this.items.map((item) => ({
        ...item,
        description: this.entryFields[item.qbField],
      }));
    },
    entryFields() {
      return this.$t("settings.automation.quickbooks.fields")[this.type];
    },
    metadata() {
      return this.$t("settings.automation.quickbooks.journalEntryReviewTable");
    },
  },
};
</script>
