<template>
  <v-simple-table class="vx-simple-table" :class="classes">
    <slot name="head" v-bind="headProps">
      <thead>
        <tr>
          <th
            v-for="header in headers"
            :key="header.text"
            :class="header.class"
            :style="{ width: header.width }"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
    </slot>
    <slot name="body" v-bind="bodyProps">
      <tbody>
        <slot name="body.prepend" />
        <slot
          v-for="(item, index) in items"
          v-bind="{ item, index }"
          name="item"
        >
          <tr :key="index">
            <td v-for="(header, hidx) in headers" :key="hidx">
              {{ item[header.value] }}
            </td>
          </tr>
        </slot>
        <slot name="body.append" />
      </tbody>
    </slot>
    <slot name="foot" />
  </v-simple-table>
</template>

<script>
export default {
  name: "VxSimpleTable",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      return { "vx-simple-table--hoverable": this.hoverable };
    },
    headProps() {
      return {
        headers: this.headers,
      };
    },
    bodyProps() {
      return {
        items: this.items,
        headers: this.headers,
      };
    },
  },
};
</script>

<style lang="scss">
.vx-simple-table:not(.vx-simple-table--hoverable) tbody tr:hover {
  background-color: transparent !important;
}

.vx-simple-table thead tr {
  text-transform: uppercase;
}
</style>
