<template>
  <VxDialog :title="title" v-bind="$attrs" persistent no-back v-on="$listeners">
    <VxAlert v-if="hasError" type="error">
      <i18n
        path="settings.automation.quickbooks.journalEntryDialog.errorAlert.message"
      >
        <template #error> {{ entryErrors }} </template>
        <template #prefix>
          <strong>{{
            $t(
              "settings.automation.quickbooks.journalEntryDialog.errorAlert.prefix"
            )
          }}</strong>
        </template>
      </i18n>
    </VxAlert>
    <VxAlert v-else-if="isNotSent" type="info">
      <i18n
        path="settings.automation.quickbooks.journalEntryDialog.notSentAlert.message"
      >
        <template #prefix>
          <strong>{{
            $t(
              "settings.automation.quickbooks.journalEntryDialog.notSentAlert.prefix"
            )
          }}</strong>
        </template>
        <template #try-again>
          <RaiLink @click="sendEntry">
            {{
              $t(
                "settings.automation.quickbooks.journalEntryDialog.notSentAlert.tryAgainLinkText"
              )
            }}
          </RaiLink>
        </template>
      </i18n>
    </VxAlert>
    <VxFormGroup :title="buysGroupTitle">
      <JournalEntryReviewTable type="buys" :items="entry.buys" />
    </VxFormGroup>
    <VxFormGroup :title="salesGroupTitle">
      <JournalEntryReviewTable type="sales" :items="entry.sales" />
    </VxFormGroup>
    <VxFormGroup :title="cogsGroupTitle">
      <JournalEntryReviewTable type="cogs" :items="entry.cogs" />
    </VxFormGroup>
    <template #actions>
      <VxBtn v-if="isNotSent || hasError" :loading="sending" @click="sendEntry">
        {{ metadata.sendToBtnText }}
      </VxBtn>
      <VxBtn text @click="close">
        {{ metadata.closeBtnText }}
      </VxBtn>
    </template>
  </VxDialog>
</template>

<script>
import { format, parseISO } from "date-fns";
import { JournalEntryStatus } from "./constants";
import JournalEntryReviewTable from "./JournalEntryReviewTable.vue";
import { VxDialog, VxBtn, VxAlert, RaiLink, VxFormGroup } from "@/core-ui";

export default {
  name: "JournalEntryDialog",
  components: {
    VxDialog,
    VxBtn,
    VxAlert,
    VxFormGroup,
    RaiLink,
    JournalEntryReviewTable,
  },
  inheritAttrs: false,
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    sending: false,
  }),
  computed: {
    title() {
      return this.$t(
        "settings.automation.quickbooks.journalEntryDialog.title",
        {
          createdAt: this.entry.createdAt
            ? format(parseISO(this.entry.createdAt), "MM/dd/yyyy")
            : "",
        }
      );
    },
    isNotSent() {
      return this.entry.status === JournalEntryStatus.NOT_SENT;
    },
    hasError() {
      return this.entry.status === JournalEntryStatus.ERROR;
    },
    entryErrors() {
      return this.entry?.errors?.join(", ") || "Unknown error";
    },
    metadata() {
      return this.$t("settings.automation.quickbooks.journalEntryDialog");
    },
    buysGroupTitle() {
      return this.$tc(
        "settings.automation.quickbooks.journalEntryDialog.buysGroupTitle",
        // use plurarization to have texts Buys vs Buys - Journal Entry
        this.entry.buysDocNum ? 2 : 1,
        {
          journalEntryNumber: this.entry.buysDocNum,
        }
      );
    },
    salesGroupTitle() {
      return this.$tc(
        "settings.automation.quickbooks.journalEntryDialog.salesGroupTitle",
        // use plurarization to have texts Sales vs Sales - Journal Entry
        this.entry.salesDocNum ? 2 : 1,
        {
          journalEntryNumber: this.entry.salesDocNum,
        }
      );
    },
    cogsGroupTitle() {
      return this.$tc(
        "settings.automation.quickbooks.journalEntryDialog.cogsGroupTitle",
        // use plurarization to have texts Inventory vs Inventory - Journal Entry
        this.entry.cogsDocNum ? 2 : 1,
        {
          journalEntryNumber: this.entry.cogsDocNum,
        }
      );
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    sendEntry() {
      this.sending = true;
      this.$emit("send:entry", {
        values: this.entry,
        resolve: () => {
          this.sending = false;
        },
      });
    },
  },
};
</script>
