<template>
  <RaiSetups v-model="activeSetup" :loading="loading">
    <ConnectAccountSetup
      :automation="automation"
      :accounts="accounts"
      :type="completedSetups[0]"
      @add:account="onAccountAdd"
      @select:account="onAccountSelect"
      @update:automation="onAutomationUpdate"
      @sync:classes="onAccountsSync"
      @next="onNext"
    />
    <JournalEntrySetup
      journal-type="buys"
      :entries="automation.buys"
      :accounts="entryAccounts"
      :type="completedSetups[1]"
      :disabled="!enableBuysJournal"
      @update:entries="onEntriesUpdate"
      @sync:accounts="onAccountsSync"
      @next="onNext"
    />
    <JournalEntrySetup
      journal-type="cogs"
      :entries="automation.cogs"
      :accounts="entryAccounts"
      :type="completedSetups[2]"
      :disabled="!enableCOGSJournal"
      @update:entries="onEntriesUpdate"
      @sync:accounts="onAccountsSync"
      @next="onNext"
    />
    <JournalEntrySetup
      journal-type="sales"
      :entries="automation.sales"
      :accounts="entryAccounts"
      :type="completedSetups[3]"
      :disabled="!enableSalesJournal"
      @update:entries="onEntriesUpdate"
      @sync:accounts="onAccountsSync"
      @next="onNext"
    />
    <AutomationSetup
      :settings="automation.settings"
      :type="completedSetups[4]"
      :entries="reviewEntries"
      :disabled="!enableSettings"
      @send:entry="onEntrySend"
      @change:entries="onEntriesChange"
      @update:settings="onSettingsUpdate"
      @next="onNext"
    />
  </RaiSetups>
</template>

<script>
import { mapState } from "vuex";
import { subscribeOneOff } from "@/utils/graphql";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { RaiSetups, SetupType } from "@/core-ui";
import { useAccountView } from "./mixins/useAccountView";
import { JournalType } from "./components/Quickbooks/constants";
import {
  ConnectAccountSetup,
  JournalEntrySetup,
  AutomationSetup,
} from "./components/Quickbooks";

import {
  QUICKBOOKS_ACCOUNT_ADD_SUBSCRIPTION,
  QUICKBOOKS_ACCOUNT_SYNCED_SUBSCRIPTION,
  QUICKBOOKS_ACCOUNTS_SELECT_QUERY,
  QUICKBOOKS_ENTRY_ACCOUNTS_SELECT_QUERY,
  QUICKBOOKS_ENTRY_ACCOUNTS_SYNC,
  QUICKBOOKS_ENTRY_ACCOUNTS_SYNCED_SUBSCRIPTION,
  QUICKBOOKS_AUTOMATION_QUERY,
  QUICKBOOKS_AUTOMATION_UPDATE,
  QUICKBOOKS_ENTRY_REVIEWS_QUERY,
  QUICKBOOKS_DAILY_JOURNAL_RESEND,
  QUICKBOOKS_DAILY_JOURNAL_RESENT_SUBSCRIPTION,
  QUICKBOOKS_CONFIG_QUERY,
} from "./graphql/Quickbooks";

export default {
  name: "QuickbooksView",
  provide() {
    return {
      view: this,
    };
  },
  components: {
    RaiSetups,
    ConnectAccountSetup,
    JournalEntrySetup,
    AutomationSetup,
  },
  mixins: [
    hasSnackbarAccess,
    useAccountView({
      prop: "quickbooksAccounts",
      query: QUICKBOOKS_ACCOUNTS_SELECT_QUERY,
    }),
  ],
  data: (vm) => ({
    activeSetup: 0,
    loading: false,
    automation: vm.buildDefaultAutomation(),
    reviewEntries: [],
    reviewEntriesInterval: {},
    completedSetups: new Array(5),
  }),
  apollo: {
    quickbooksConfig: {
      query: QUICKBOOKS_CONFIG_QUERY,
      update(response) {
        return response.quickbooksConfig;
      },
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      skip() {
        return !this.storeId;
      },
    },
    automation: {
      query: QUICKBOOKS_AUTOMATION_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
      update(response) {
        return response.quickbooksAutomation || this.buildDefaultAutomation();
      },
      skip() {
        return !this.storeId;
      },
      watchLoading(isLoading) {
        this.setLoading(isLoading);
      },
    },
    entryAccounts: {
      query: QUICKBOOKS_ENTRY_ACCOUNTS_SELECT_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          integrationQuickbooksId: this.automation?.integrationQuickbooksId,
        };
      },
      update(response) {
        return response.quickbooksEntryAccounts;
      },
      skip() {
        return !this.storeId || !this.hasConnectedAccount;
      },
    },
    $subscribe: {
      quickbooksAccountAdded: {
        query: QUICKBOOKS_ACCOUNT_ADD_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
      quickbooksAccountSynced: {
        query: QUICKBOOKS_ACCOUNT_SYNCED_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
      quickbooksEntryAccountsSynced: {
        query: QUICKBOOKS_ENTRY_ACCOUNTS_SYNCED_SUBSCRIPTION,
        variables() {
          return {
            integrationQuickbooksId: this.automation?.integrationQuickbooksId,
            storeId: this.storeId,
          };
        },
        skip() {
          return !this.storeId || !this.hasConnectedAccount;
        },
      },
      quickbooksDailyJournalResent: {
        query: QUICKBOOKS_DAILY_JOURNAL_RESENT_SUBSCRIPTION,
        variables() {
          return { userId: this.userId, storeId: this.storeId };
        },
        skip() {
          return !this.userId || !this.storeId;
        },
      },
    },
  },
  computed: {
    ...mapState("auth", { userId: "user_id" }),
    hasConnectedAccount() {
      return this.automation?.integrationQuickbooksId > 0;
    },
    enableBuysJournal() {
      return this.hasConnectedAccount;
    },
    enableCOGSJournal() {
      return this.isValidJournal(JournalType.BUYS);
    },
    enableSalesJournal() {
      return this.isValidJournal(JournalType.COGS);
    },
    enableSettings() {
      return this.isValidJournal(JournalType.SALES);
    },
  },
  methods: {
    buildDefaultAutomation() {
      return {
        integrationQuickbooksId: null,
        integrationQuickbooksClassId: null,
        settings: {},
        buys: [],
        cogs: [],
        sales: [],
      };
    },
    isValidJournal(journalType) {
      const journal = this.automation[journalType] || [];
      return (
        this.hasConnectedAccount &&
        journal.every((x) => x.qbAccountId) &&
        journal.length > 0
      );
    },
    setLoading(value) {
      this.loading = value;
    },
    async onNext() {
      this.completedSetups[this.activeSetup] = SetupType.COMPLETE;
      this.activeSetup++;
    },
    onAccountAdd(context) {
      this.subscribeToAccountAdded(context);
      this.subscribeToAccountSynced(context);

      window.open(
        this.quickbooksConfig.authorizationUrl,
        "ccAuthWindow",
        "toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1,width=800,height=600"
      );
    },
    onAccountSelect(values) {
      if (!this.automation) {
        this.automation = {};
      }
      this.automation.integrationQuickbooksId = values.integrationQuickbooksId;
    },
    subscribeToAccountAdded(context) {
      subscribeOneOff(this, {
        name: "quickbooksAccountAdded",
        resolve: (result) => {
          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.quickbooks.accountAddedSuccessText"
                )
              : this.$t(
                  "settings.automation.quickbooks.accountAddedErrorText",
                  { error: result.errors.join(", ") }
                ),
          });

          if (!result.success) {
            context.resolve();
          }
        },
      });
    },
    subscribeToAccountSynced(context) {
      subscribeOneOff(this, {
        name: "quickbooksAccountSynced",
        resolve: async (result) => {
          context.resolve();

          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.quickbooks.accountSyncedSuccessText"
                )
              : this.$t(
                  "settings.automation.quickbooks.accountSyncedErrorText",
                  { error: result.errors.join(", ") }
                ),
          });

          await this.$apollo.queries.automation.refetch();
          await this.$apollo.queries.accountList.refetch();
          await this.$apollo.queries.entryAccounts.refetch();
        },
      });
    },
    async onAccountsSync(context) {
      this.subscribeToEntryAccountsSynced(context);

      await this.$apollo.mutate({
        mutation: QUICKBOOKS_ENTRY_ACCOUNTS_SYNC,
        variables: {
          storeId: this.storeId,
          integrationQuickbooksId: this.automation?.integrationQuickbooksId,
        },
      });
    },
    subscribeToEntryAccountsSynced(context) {
      subscribeOneOff(this, {
        name: "quickbooksEntryAccountsSynced",
        resolve: async (result) => {
          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.quickbooks.qbAccountsSyncedSuccessText"
                )
              : this.$t(
                  "settings.automation.quickbooks.qbAccountsSyncedErrorText",
                  { error: result.errors.join(", ") }
                ),
          });

          await this.$apollo.queries.accountList.refetch();
          await this.$apollo.queries.entryAccounts.refetch();

          context.resolve();
        },
      });
    },
    async onAutomationUpdate({ values, resolve }) {
      const {
        data: { quickbooksAutomationUpdate },
      } = await this.$apollo.mutate({
        mutation: QUICKBOOKS_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: values,
        },
      });

      if (quickbooksAutomationUpdate.errors?.length > 0) {
        console.error("ERROR: Quickbooks Automation Update");
        return;
      }

      this.automation = {
        ...this.automation,
        ...quickbooksAutomationUpdate.automation,
      };

      resolve();
    },
    async onEntrySend(context) {
      this.subscribeToEntrySent(context);

      let date = null;
      if (
        context.values?.createdAt !== null &&
        context.values?.createdAt !== undefined
      ) {
        // TODO: remove toISOString
        date = context.values?.createdAt
          ? new Date(context.values.createdAt).toISOString()
          : null;
      }

      await this.$apollo.mutate({
        mutation: QUICKBOOKS_DAILY_JOURNAL_RESEND,
        variables: {
          storeId: this.storeId,
          date: date,
        },
      });
    },
    subscribeToEntrySent(context) {
      subscribeOneOff(this, {
        name: "quickbooksDailyJournalResent",
        resolve: async (result) => {
          this.showSnackbar({
            text: result.success
              ? this.$t(
                  "settings.automation.quickbooks.resendJournalSuccessText"
                )
              : this.$t(
                  "settings.automation.quickbooks.resendJournalErrorText",
                  { error: result.errors.join(", ") }
                ),
          });

          const { data } = await this.$apollo.query({
            query: QUICKBOOKS_ENTRY_REVIEWS_QUERY,
            fetchPolicy: "network-only",
            variables: {
              storeId: this.storeId,
              ...this.reviewEntriesInterval,
            },
          });
          this.reviewEntries = data.quickbooksEntryReviews;

          context.resolve();
        },
      });
    },
    async onEntriesChange({ values, resolve }) {
      this.reviewEntriesInterval = values;

      const { data } = await this.$apollo.query({
        query: QUICKBOOKS_ENTRY_REVIEWS_QUERY,
        variables: {
          storeId: this.storeId,
          ...values,
        },
      });

      this.reviewEntries = data?.quickbooksEntryReviews ?? [];
      resolve();
    },
    async onEntriesUpdate({ values, type, resolve }) {
      const {
        data: { quickbooksAutomationUpdate },
      } = await this.$apollo.mutate({
        mutation: QUICKBOOKS_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: { [type]: values },
        },
      });

      if (quickbooksAutomationUpdate.errors?.length > 0) {
        console.error("ERROR: Quickbooks Entries Update");
        return;
      }

      this.automation[type] = quickbooksAutomationUpdate.automation[type];
      resolve();
    },
    async onSettingsUpdate({ values, resolve }) {
      const {
        data: { quickbooksAutomationUpdate },
      } = await this.$apollo.mutate({
        mutation: QUICKBOOKS_AUTOMATION_UPDATE,
        variables: {
          storeId: this.storeId,
          input: {
            settings: values,
          },
        },
      });

      if (quickbooksAutomationUpdate.errors?.length > 0) {
        console.error("ERROR: Quickbooks Automation Settings Update");
        return;
      }

      this.automation.settings = quickbooksAutomationUpdate.automation.settings;
      resolve();
    },
  },
};
</script>
