<script>
import { VIcon } from "vuetify/lib";
import { JournalEntryStatus } from "./constants";

const ENTRY_STATUS_CONFIG = {
  [JournalEntryStatus.SENT]: {
    color: "success",
    icon: "$complete",
  },
  [JournalEntryStatus.NOT_SENT]: {
    color: "primary",
    icon: "$sync",
  },
  [JournalEntryStatus.ERROR]: {
    color: "error",
    icon: "$error",
  },
};

export default {
  name: "JournalEntryCalendarDay",
  functional: true,
  props: {
    entry: {
      type: Object,
      default: () => ({ status: null }),
    },
  },
  render(h, { props }) {
    const { color, icon } = ENTRY_STATUS_CONFIG[props.entry.status] || {};
    return h("div", { class: "d-flex justify-center mt-3" }, [
      h(VIcon, { props: { size: 20, color } }, icon),
    ]);
  },
};
</script>
