<template>
  <SetupForm
    :title="metadata.title"
    :next-text="metadata.nextText"
    v-bind="$attrs"
    v-on="$listeners"
    @submit="updateSettings"
  >
    <template #extension>
      <p v-for="(text, idx) in metadata.extension" :key="idx">
        {{ text }}
      </p>
    </template>
    <RaiSetupToggle
      v-model="form.active"
      name="active"
      :title="metadata.activeToggle.title"
      :subtitle="metadata.activeToggle.subtitle"
    />
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <VxDatePicker
          v-model="form.startDate"
          :label="metadata.startDate.label"
          :placeholder="metadata.startDate.placeholder"
          name="startDate"
        />
      </v-col>
    </v-row>
    <VxFormGroup :title="metadata.reviewGroup.title">
      <p>
        {{ metadata.reviewGroup.description }}
      </p>
      <JournalEntryCalendar
        :loading="entriesLoading"
        :entries="entries"
        @change="changeEntries"
        @click:entry="onEntryClick"
      />
      <JournalEntryDialog
        v-model="showEntry"
        :entry="selectedEntry"
        @send:entry="sendEntry"
        @close="onJournalEntryDialogClose"
      />
    </VxFormGroup>
  </SetupForm>
</template>

<script>
import { addDays } from "date-fns/";
import { useFormObject } from "@/mixins/useFormObject";

import SetupForm from "../SetupForm.vue";
import JournalEntryCalendar from "./JournalEntryCalendar.vue";
import JournalEntryDialog from "./JournalEntryDialog.vue";
import { RaiSetupToggle, VxFormGroup, VxDatePicker } from "@/core-ui";

export default {
  name: "AutomationSetup",
  components: {
    SetupForm,
    RaiSetupToggle,
    VxFormGroup,
    VxDatePicker,
    JournalEntryCalendar,
    JournalEntryDialog,
  },
  mixins: [
    useFormObject({
      prop: "settings",
      default: {
        active: false,
        startDate: null,
      },
    }),
  ],
  props: {
    entries: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showEntry: false,
    selectedEntryDate: null,
    entriesLoading: false,
  }),
  computed: {
    metadata() {
      return this.$t("settings.automation.quickbooks.automationSetup");
    },
    selectedEntry() {
      return (
        this.entries.find(
          (entry) => entry.createdAt == this.selectedEntryDate
        ) || {}
      );
    },
  },
  methods: {
    onEntryClick(entry) {
      this.showEntry = true;
      this.selectedEntryDate = entry.createdAt;
    },
    onJournalEntryDialogClose() {
      this.showEntry = false;
      this.selectedEntryDate = null;
    },
    sendEntry(entry) {
      this.$emit("send:entry", entry);
    },
    changeEntries(values) {
      this.entriesLoading = true;
      this.$emit("change:entries", {
        values,
        resolve: () => {
          this.entriesLoading = false;
        },
      });
    },
    updateSettings(form) {
      this.$emit("update:settings", form);
    },
  },
};
</script>
