<template>
  <td :class="['rai-data', 'text-end', addClass]">
    {{ formattedValue }}
  </td>
</template>

<script>
export default {
  name: "MoneyCell",
  props: {
    value: {
      type: Number,
      default: null,
    },
    locales: {
      type: String,
      default: "en-US",
    },
    currency: {
      type: String,
      default: "USD",
    },
    addClass: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    formattedValue() {
      return this.value?.toLocaleString(this.locales, {
        style: "currency",
        currency: this.currency,
      });
    },
  },
};
</script>
